import * as React from "react";
const SVGComponent = (props) => (
  <svg
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        "\n\t.st0{fill:url(#SVGID_1_);}\n\t.st1{opacity:0.75;}\n\t.st2{fill:#FFFFFF;}\n\t.st3{opacity:0.5;}\n\t.st4{opacity:0.25;}\n\t.st5{fill:#F0F1F5;}\n"
      }
    </style>
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={1.0814}
        y1={256.4593}
        x2={510}
        y2={256.4593}
      >
        <stop
          offset={0}
          style={{
            stopColor: "#02639A",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0FB6C7",
          }}
        />
      </linearGradient>
      <path
        className="st0"
        d="M510,256.5c0,19-2.1,37.6-6.1,55.5c-20,90.1-87.9,162.3-175.6,188.4c-23.1,6.9-47.5,10.6-72.8,10.6H1.1V256.5 c0-9.1,0.5-18,1.4-26.9c10.8-103.2,83.4-188.1,180.2-217C205.8,5.7,230.2,2,255.5,2c13.8,0,27.4,1.1,40.7,3.2 c54,8.7,102.3,34.3,139.3,71.3c31.7,31.7,55.1,71.6,66.6,116.4C507.2,213.3,510,234.5,510,256.5z"
      />
      <g className="st1">
        <path
          className="st2"
          d="M413.4,122.4L296.2,5.2C283,3.1,269.4,2,255.5,2c-25.3,0-49.8,3.7-72.9,10.6l54.2,54.3 c-40,6.7-75.2,25.2-105.6,55.6c-38.9,39-58.4,86-58.4,141.1c0,55.1,19.5,102.1,58.4,141c38.9,38.9,86,58.4,141,58.4 c55.1,0,102.1-19.5,141.1-58.4c38.9-38.9,58.4-86,58.4-141C471.8,208.4,452.4,161.4,413.4,122.4z M353,344.1 c-22.3,22.3-49.1,33.4-80.6,33.4c-31.4,0-58.3-11.1-80.6-33.4c-22.3-22.3-33.4-49.1-33.4-80.6c0-31.5,11.1-58.3,33.4-80.6 c22.3-22.3,49.1-33.4,80.6-33.4c31.5,0,58.3,11.1,80.6,33.4c22.3,22.3,33.4,49.1,33.4,80.6C386.4,294.9,375.3,321.8,353,344.1z"
        />
      </g>
      <g className="st3">
        <path
          className="st2"
          d="M502,192.9l-44.9,44.9c-6.7-40-25.2-75.2-55.6-105.6c-39-38.9-86-58.4-141.1-58.4 c-55.1,0-102.1,19.5-141,58.4c-38.9,39-58.4,86-58.4,141.1c0,55.1,19.5,102.1,58.4,141c38.9,38.9,86,58.4,141,58.4 c55.1,0,102.1-19.5,141-58.4l102.4-102.4c4-17.8,6.1-36.4,6.1-55.5C510,234.5,507.2,213.3,502,192.9z M341.1,353.9 c-22.3,22.3-49.2,33.4-80.6,33.4s-58.3-11.1-80.6-33.4c-22.3-22.3-33.4-49.1-33.4-80.6c0-31.5,11.1-58.3,33.4-80.6 c22.3-22.3,49.1-33.4,80.6-33.4s58.3,11.1,80.6,33.4c22.3,22.3,33.4,49.1,33.4,80.6C374.5,304.7,363.4,331.6,341.1,353.9z"
        />
      </g>
      <g className="st4">
        <path
          className="st2"
          d="M450.1,261.4c0-55.1-19.5-102.1-58.5-141.1c-38.9-38.9-86-58.4-141-58.4c-55.1,0-102.1,19.5-141,58.4 c-39,39-58.4,86-58.4,141.1c0,55.1,19.5,102.1,58.4,141l108.5,108.5h37.4c25.3,0,49.7-3.7,72.8-10.6l-42.3-42.3 c40-6.7,75.2-25.2,105.6-55.6C430.6,363.5,450.1,316.5,450.1,261.4z M331.2,342c-22.3,22.3-49.2,33.4-80.6,33.4 c-31.4,0-58.3-11.1-80.6-33.4c-22.3-22.3-33.4-49.1-33.4-80.6c0-31.5,11.1-58.3,33.4-80.6c22.3-22.3,49.1-33.4,80.6-33.4 c31.4,0,58.3,11.1,80.6,33.4c22.3,22.3,33.4,49.1,33.4,80.6C364.7,292.9,353.5,319.7,331.2,342z"
        />
      </g>
      <g className="st3">
        <path
          className="st2"
          d="M403.5,110.5c-38.9-38.9-86-58.4-141-58.4c-55.1,0-102.1,19.5-141,58.4L2.5,229.6c-0.9,8.8-1.4,17.8-1.4,26.9 v95.4l64.8-64.8c6.7,40,25.2,75.2,55.6,105.6c38.9,38.9,86,58.4,141,58.4c55.1,0,102.1-19.5,141-58.4c39-38.9,58.4-86,58.4-141 C462,196.5,442.5,149.5,403.5,110.5z M343.1,332.2c-22.3,22.3-49.1,33.4-80.6,33.4c-31.4,0-58.3-11.1-80.6-33.4 c-22.3-22.3-33.4-49.1-33.4-80.6c0-31.4,11.1-58.3,33.4-80.6c22.3-22.3,49.2-33.4,80.6-33.4c31.4,0,58.3,11.1,80.6,33.4 c22.3,22.3,33.5,49.2,33.5,80.6C376.6,283,365.4,309.9,343.1,332.2z"
        />
      </g>
      <g>
        <path
          className="st5"
          d="M395.6,113c-39-38.9-86-58.4-141.1-58.4c-55.1,0-102.1,19.5-141.1,58.4C74.6,152,55.1,199,55.1,254v256.9 h85.5v-92.8c33.1,23.6,71,35.3,114,35.3c55.1,0,102.1-19.4,141.1-58.4c38.9-38.9,58.4-86,58.4-141.1C454,199,434.5,152,395.6,113z  M335.2,334.7C313,356.9,286.1,368,254.6,368s-58.4-11.1-80.6-33.3c-22.2-22.2-33.3-49.1-33.3-80.6c0-31.5,11.1-58.4,33.3-80.6 s49.1-33.3,80.6-33.3s58.4,11.1,80.6,33.3s33.3,49.1,33.3,80.6C368.5,285.6,357.4,312.5,335.2,334.7z"
        />
      </g>
    </g>
  </svg>
);
export default SVGComponent;
